import { Button, useMediaQuery, useTheme } from '@mui/material';

export const ChatInputButton = ({ children, onClick, startIcon }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      size={isMobile ? 'small' : 'medium'}
      startIcon={ isMobile ? undefined : children === null ? undefined : startIcon}
      sx={{
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
        borderRadius: isMobile ? 2 : 3,
        backgroundColor: theme.palette.chatBackground,
        '&:hover': {
          backgroundColor: theme.palette.chatBackground,
          opacity: 1,
          color: 'primary.dark',
        },
        minWidth: 0,
      }}
    >
      { isMobile ? startIcon : children === null ? startIcon : children }
    </Button>
  );
}