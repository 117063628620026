import React, { useEffect, useRef, useState } from 'react';
import { isBotLoggedIn, getBotSettingItem } from '../AuthService.js'
import * as default_data from '../config.js'
import AudioRecorder from './AudioRecorder.js';
import { Button, Grid, Box, Stack, Typography, Input, Chip, useTheme, Tooltip, tooltipClasses, useMediaQuery } from '@mui/material'
import { SelectPersona } from './Components/SelectPersona.js';
import { SelectModel } from './Components/SelectModel.js';
import { SelectLive } from './Components/SelectLive.js';
import { SelectTemperature } from './Components/SelectTemperature.js';
import { SelectDataset } from './Components/SelectDataset.js';
import { FiDatabase,FiFolder,  } from "react-icons/fi";
import { ImFilesEmpty } from "react-icons/im";
import { FiUploadCloud, FiPaperclip } from "react-icons/fi"
import { useDropzone } from 'react-dropzone';
import { validateFile } from '../Utils/file_functions.js';
import PromptList from '../Prompts/PromptList.js'
import PluginList from '../Plugins/PluginList.js'
import { FaInfoCircle } from 'react-icons/fa';
import { CustomTooltip } from '../Components/CustomTooltip.js';

export const ChatInput = ({
  getTokenizer,
  handleSubmit,
  prevCommand,
  setFile,
  setCurrentPersona,
  currentMode,
  current_dataset,
  datasets,
  handleDataset,
  showSimpleIngest,
  showCopyData,
  handleLive,
  live,
  paid,
  showPersonaList,
  current_persona,
  personas,
  handlePersona,
  handleSelect,
  temperature,
  currentModel,
  handleModel,
  cur_user,
  handleFileChange,
  handleAudio,
  showAddDataset,
  setChatLogAndSaveWithScroll,
  secrets,
  tokens_count,
  getTokenWindow,
  file,
  isCUICapable,
  chatInputPass,
  setChatInputPass,
  handlePluginClick
}) => {
  const theme = useTheme();
  const [chatInput, setChatInput] = useState("");
  const pluginListRef = useRef(null);
  const [triggerPromptList, setTriggerPromptList] = useState(0);
  const [PromptListMode, setPromptListMode] = useState("normal");
  const [PromptListValue, setPromptListValue] = useState(null);
  const [triggerPluginList, setTriggerPluginList] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const hideTempature = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Call your function here
      getTokenizer(chatInput)
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [chatInput]);

  useEffect(() => {
    if (chatInputPass !== undefined) {
      setChatInput(chatInputPass)
      setChatInputPass(undefined)
    }
  }, [chatInputPass, setChatInputPass])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (!validateFile(acceptedFiles[0])) {
        alert('Invalid file type. Please upload a valid file type.');
        return;
      }
      setFile(acceptedFiles[0]);
    },
  });

  function handlePluginClickExec(plugin_name, plugin_values, prompt) {
    return handlePluginClick(plugin_name, plugin_values, prompt, setChatInput)
  }

  function handlePromptClick(prompt, persona=1) {
    setChatInput(prompt)
    setCurrentPersona(persona);
  }

  async function keyHandler(e) {
    if (e.which === 13 && e.ctrlKey) {
      handleSubmit(e, chatInput, setChatInput);
    } else if (e.which === 38 && e.ctrlKey) {
      const chatInputValue = chatInput?.trim() || undefined;
      const newPrevCommand = prevCommand.filter((item, index, self) => {  
        return index === self.indexOf(item);
      });
      if (chatInputValue === '' || chatInputValue === undefined || !newPrevCommand.includes(chatInput)) {
        setChatInput(newPrevCommand[newPrevCommand.length - 1]);
      } else {
        const index = newPrevCommand.indexOf(chatInput);
        if (index > 0) {
          setChatInput(newPrevCommand[index - 1]);
        }
      }
    } else if (e.which === 40 && e.ctrlKey) {
      const chatInputValue = chatInput?.trim() || undefined;
      const newPrevCommand = prevCommand.filter((item, index, self) => {  
        return index === self.indexOf(item);
      });
      if (chatInputValue === '' || chatInputValue === undefined || !newPrevCommand.includes(chatInput)) {
        setChatInput(newPrevCommand[0]);
      } else {
        const index = newPrevCommand.indexOf(chatInput);
        if (index < newPrevCommand.length - 1) {
          setChatInput(newPrevCommand[index + 1]);
        }
      }
    }
  }

  const file_style = {
    fileInput: {
      display: 'none', 
    },
    fileLabel: {
      background: 'url(file.png) no-repeat'
    }
  };

  function showPluginList() {
    setTriggerPluginList(1);
  }

  function showPromptList(type='normal', field_name) {
    setTriggerPromptList(1);
    setPromptListMode(type);
    setPromptListValue(field_name);
  }

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e, chatInput, setChatInput)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginX: 1,
            marginBottom: 1,
            padding: 2,
            borderRadius: 2,

          }}
          bgcolor={theme.palette.chatInputBackground}
        >
          <Grid container spacing={1} mb={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: {xs: 'column', sm: 'row'},
                gap: 1,
                alignItems: 'start',
              }}
            >
              { !isBotLoggedIn() && (
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={{xs: 0.3, md:1}}>
                  { getBotSettingItem('force_datasets') === null && (
                    <div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
                      <Typography mr={0.5} className="chat-input-label">{ currentMode === 'simple' ? 'Data Collection: ' : 'Datasets: '}</Typography>
                      {!isMobile && <CustomTooltip 
                        title={'Dataset: collection of files/data used to augment your AI model knowledge'} 
                        arrow
                      >
                        <Box sx={{
                          marginRight: 1,
                          marginTop: -2,
                        }}>
                          <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                        </Box>
                      </CustomTooltip>}
                      <SelectDataset
                        value={current_dataset}
                        datasets={datasets}
                        onChange={handleDataset}
                      />
                    </div>
                  )}
                  
                    <Button
                      style={{height: '38px', minWidth: 0, textTransform: 'none'}}
                      onClick={showSimpleIngest}
                      size='small'
                    >
                      <FiUploadCloud alt="Ingest Data" color={theme.palette.text.primary} size={16} />
                      <Typography fontSize={'11pt'} display={{xs: 'none', md: 'block'}} marginLeft={{xs:0, md: 1}} color={theme.palette.text.primary} noWrap>Ingest Files</Typography>
                      {!isMobile && <CustomTooltip 
                        title={'Upload files into a dataset'} 
                        arrow
                      >
                        <Box marginLeft={0.5} marginTop={-2}>
                          <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                        </Box>
                      </CustomTooltip>}
                    </Button>
                  
                  {/* <Tooltip arrow title={'Manage and copy files in datasets'} enterDelay={tooltipDelay}> */}
                    <Button
                      style={{height: '38px', minWidth: 0, textTransform: 'none'}}
                      onClick={showCopyData}
                    >
                      <ImFilesEmpty alt="Manage Files"  color={theme.palette.text.primary} size={16} />
                      <Typography fontSize={'11pt'} display={{xs: 'none', md: 'block'}} marginLeft={{xs:0, md: 1}} color={theme.palette.text.primary} >Manage Files</Typography>
                      {!isMobile && <CustomTooltip 
                        title={'Manage your files'} 
                        arrow
                      >
                        <Box marginLeft={0.5} marginTop={-2}>
                          <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                        </Box>
                      </CustomTooltip>}
                    </Button>
                    
                  {(default_data.default_block_live !== true && currentMode !== 'simple') && (
                      <div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
                        <Typography className="chat-input-label-large" style={{marginRight:4, marginLeft: 4, fontSize: '11pt'}}>
                          { currentMode === 'simple' ? 'Bing:' : 'Live:' }
                        </Typography>
                        {!isMobile && <CustomTooltip 
                          title={'Off: No live result used. Live: Ask Sage will use data from Bing to answer questions. Live+: Ask Sage will also summarize the web pages of the top 2 results.'} 
                          arrow
                        >
                        <Box sx={{
                          marginRight: 1,
                          marginTop: -2,
                        }}>
                            <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                          </Box>
                        </CustomTooltip>}
                        <SelectLive
                          currentMode={currentMode}
                          onChange={handleLive}
                          paid={paid}
                        />
                       
                      </div>
                  )}
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                alignItems: 'center',
              }}
            >
              {(getBotSettingItem('force_persona') === null && currentMode !== 'simple') && (
                <div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
                  <Typography className="chat-input-label-large" style={{marginRight:4, marginLeft: 4, fontSize: '11pt'}}>
                    Personality:
                  </Typography>
                  {!isMobile && <CustomTooltip 
                    title={'Persona: pick a personality adapted to your use case to help answering questions'} 
                    arrow
                  >
                  <Box sx={{
                    marginRight: 1,
                    marginTop: -2,
                  }}>
                      <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                    </Box>
                  </CustomTooltip>}
                  <SelectPersona
                    showPersonaList={showPersonaList}
                    value={current_persona}
                    personas={personas}
                    onChange={handlePersona}
                  />
                </div>
              )}
              {(getBotSettingItem('force_temperature') === null && currentMode !== 'simple' && !hideTempature) && (
                <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
                  <Typography className="chat-input-label-large" style={{marginRight:4, marginLeft: 4, fontSize: '11pt'}}>
                    Temperature:
                  </Typography>
                  {!isMobile && <CustomTooltip 
                    title={'Temperature: keep at 0 for factual, 1 for creative'} 
                    arrow
                  >
                  <Box sx={{
                    marginRight: 1,
                    marginTop: -2,
                  }}>
                      <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                    </Box>
                  </CustomTooltip>}
                  <SelectTemperature
                    onChange={handleSelect}
                    temperature={temperature}
                  />
                </Box>
              )}
              {(getBotSettingItem('force_model') === null ** currentMode !== 'simple') && (
                <div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
                  <Typography className="chat-input-label-large" style={{marginRight:4, marginLeft: 4, fontSize: '11pt'}}>
                    {currentMode === 'simple' ? 'Models:' : ''}
                  </Typography>
                  { !isMobile && currentMode === 'simple' && (
                    <CustomTooltip 
                      title={'Model: the AI model we will use to answer questions'} 
                      arrow
                    >
                    <Box sx={{
                      marginRight: 1,
                      marginTop: -2,
                    }}>
                        <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                      </Box>
                    </CustomTooltip>
                  )}
                  <SelectModel
                    value={currentModel}
                    cur_user={cur_user}
                    onChange={handleModel}
                    paid={paid}
                    sx={{
                      width: '150px',
                    }}
                  />
                </div>
              )}
              {(getBotSettingItem('hide_prompt_template') === null) && (
                <Button
                  className="btn-small"
                  sx={{
                    minWidth: 0,
                    height: '35px',
                    textTransform: 'none',
                  }}
                  onClick={showPromptList}
                >
                  <FiFolder alt="Prompts" color={theme.palette.text.primary} size={16} />
                  <Typography fontSize={'11pt'} display={{xs: 'none', md: 'block'}} marginLeft={{xs:0, md: 1}} color={theme.palette.text.primary}>
                    { currentMode === 'simple' ? 'Tasks' : 'Prompt Templates' }
                  </Typography>
                  {!isMobile && <CustomTooltip 
                      title={'Prompt Template: pre-written prompts to help you get started with various use cases'} 
                      arrow
                    >
                      <Box marginLeft={0.5} marginTop={-2}>
                        <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                      </Box>
                    </CustomTooltip>
                  }
                </Button>
              )}
              {(getBotSettingItem('hide_plugins') === null) && (
                <Button
                  className="btn-small"
                  sx={{
                    minWidth: 0,
                    height: '35px',
                    textTransform: 'none',
                  }}
                  onClick={showPluginList}
                >
                  <FiDatabase color={theme.palette.text.primary} size={16} alt={'Plugins'} />
                  <Typography fontSize={'11pt'} display={{xs: 'none', md: 'block'}} marginLeft={{xs:0, md: 1}} color={theme.palette.text.primary}>Plugins</Typography>
                  {!isMobile && <CustomTooltip 
                      title={'Plugins/Agents: pre-built tasks to help you get started with various use cases'} 
                      arrow
                    >
                      <Box marginLeft={0.5} marginTop={-2}>
                        <FaInfoCircle color={theme.palette.tooltip.iconColor} size={11}/>
                      </Box>
                    </CustomTooltip>}
                </Button>
              )}
            </Grid>
          </Grid>
          <Box className="chat-input-holder-item">
            {(!isBotLoggedIn() || !getBotSettingItem('hide_fileupload')) && (
              file !== null ? (
                <Box className="file-info" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <img src="file-blue.png" style={{width: '20px'}} alt="Attachment" />
                  <Typography variant="body2">{file.name}</Typography>
                  <Button size="small" onClick={() => setFile(null)} sx={{ minWidth: 'auto' }}>X</Button>|
                  {!default_data.default_hide_cac && (
                    isCUICapable() ?
                      <Chip label="CUI compliant" color="success" size="small" />
                    :
                      <Chip label="Not CUI compliant" color="error" size="small" />
                  )}
                  <Typography variant="body2" sx={{ color: tokens_count > getTokenWindow() ? 'error.main' : 'inherit' }}>
                    {tokens_count.toLocaleString()} / {getTokenWindow().toLocaleString()} tokens
                  </Typography>
                </Box>
              ) : (
                <Box className="file-info" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2">No file attached</Typography>|
                  {!default_data.default_hide_cac && (
                    isCUICapable() ?
                      <Chip label="CUI compliant" color="success" size="small" />
                    :
                      <Chip label="Not CUI compliant" color="error" size="small" />
                  )}
                  <Typography variant="body2" sx={{ color: tokens_count > getTokenWindow() ? 'error.main' : 'inherit' }}>
                    {tokens_count.toLocaleString()} / {getTokenWindow().toLocaleString()} tokens
                  </Typography>
                </Box>
              )
            )}
            <div className="textarea-buttons-block" {...getRootProps()} onClick={()=>{}} onChange={()=>{}}>
              <input {...getInputProps()} onClick={()=>{}} onChange={()=>{}} />
              <Box display={isDragActive ? 'block' : 'none'} border={'grey dashed 2px'} height={'160px'} color={'grey'} padding={1} borderRadius={2}>
                <p>
                  <strong>Drag and a drop file here</strong>
                  <br />
                  Format supported: zip, pdf, xlsx, pptx, docx, ppt, csv, cc, sql, cs, hh, c, php, js, py, html, xml, msg, odt, epub, eml, rtf, txt, doc, json, md, jpeg, jpg, png, tsv, yaml, yml, java, rb, sh, bat, ps1 (50MB)
                  <br />
                  Audio Format supported: mp3, mp4, mpeg, mpga, m4a, wav, webm (500MB max)
                </p>
              </Box>
              <textarea
                value={chatInput}
                style={{display: isDragActive ? 'none' : 'block'}}
                onKeyDown={(e) => keyHandler(e)}
                onChange={(e)=> setChatInput(e.target.value)}
                placeholder="Enter your question here and/or drop a file"
                className="chat-input-textarea"
              />
              <Stack spacing={1.5} style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                {
                  default_data.default_voicerecorder_enabled === true && paid == true ?
                    <div style={{margin: 'auto'}}>
                      <AudioRecorder handleAudio={handleAudio} chatInput={chatInput} setChatInput={setChatInput} style={{margin: '5px'}} />
                    </div>
                    : ''
                }
                <div>
                  { 
                    isBotLoggedIn() && getBotSettingItem('hide_fileupload') === true ?
                    '' :
                        <span className="file-paperclip">
                          <Input style={file_style.fileInput} id="file" name="file" type="file" onChange={handleFileChange} />
                          <label 
                            className="chat-log-file-btn" 
                            htmlFor="file" 
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <FiPaperclip alt="Attach File" color={theme.palette.text.primary} size={20} />
                          </label>
                        </span>

                  }
                </div>
                <div style={{margin: '5px auto'}}>
                  <button className="submit" style={{height: '40px', margin: 'auto'}} type="submit">
                    <img src="send.png" style={{width: '20px'}} alt="Send" />
                  </button>
                </div>
              </Stack>
            </div>
          </Box>
        </Box>
      </form>
      {triggerPluginList === 1 && <PluginList showAddDataset={showAddDataset} ref={pluginListRef} showPromptList={showPromptList} handleBotMessage={setChatLogAndSaveWithScroll} secrets={secrets} datasets={datasets} setTriggerPluginList={setTriggerPluginList} triggerPluginList={triggerPluginList} handlePluginClick={handlePluginClickExec} />}
      {triggerPromptList === 1 && <PromptList setTriggerPromptList={setTriggerPromptList} triggerPromptList={triggerPromptList} personas={personas} handlePromptClick={handlePromptClick} />}
    </>
  )
}