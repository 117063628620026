import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FiChevronDown } from 'react-icons/fi';
import { sanitizeHtml } from '../Utils/text_functions';
import { Typography } from '@mui/material';

export const AccordionFileComponent = ({ content }) => {
    return (
      content.accordion !== undefined && content.accordion !== null && content.accordion !== 'null' ? 
        <Accordion sx={{border: '1px solid #ccc', borderRadius: '5px', marginBottom: '5px'}}>
        <AccordionSummary expandIcon={<FiChevronDown />}>
          <Typography>File Content Ingested</Typography>
        </AccordionSummary>
        <AccordionDetails dangerouslySetInnerHTML={{__html: sanitizeHtml(content.accordion.replace(/\n/g, "<br />"), ['br'])}} />
      </Accordion>
    : null
  )}
  