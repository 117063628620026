import React, { useEffect, useState } from 'react';
import { Drawer, Stack, Typography, IconButton, Tabs, Tab } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { CustomizationTab } from './CustomizationTab';
import { TokensTab } from './TokensTab';
import { useFetchMonthlyTokens, useFetchTeachTokens, useFetchMaxTokens, useFetchUser } from "./query"
import { AccountTab } from './AccountTab';
import { getToken, logout } from '../../AuthService';
import * as default_data from '../../config.js'

export const SettingsDrawer = ({ 
  open,
  onClose,
  setShowAPIKeysList,
  settingsTabValue,
}) => {  
  const [tab, setTab] = useState(settingsTabValue || 'customization');
  const [personas, setPersonas] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const { 
    data: inferenceTokens, 
    loading: loadingInferenceTokens, 
    refetch: refetchInferenceTokens,
    error: errorInferenceTokens,
  } = useFetchMonthlyTokens();

  const { 
    data: teachTokens, 
    loading: loadingTeachTokens, 
    refetch: refetchTeachTokens,
    error: errorTeachTokens,
  } = useFetchTeachTokens();

  const {
    maxInferenceTokens,
    maxTeachTokens,
    loading: loadingMaxTokens,
    refetch: refetchMaxTokens,
    error: errorMaxTokens,
  }= useFetchMaxTokens();

  const {user, loading, refetch: updateInfo} = useFetchUser();

  useEffect(() => {
    loadPersonas();
    loadDatasets();
  }, []);

  function loadDatasets() {
    var token = getToken()
    if(token === false) {
        logout()
        return;
    }
  
    fetch(default_data.default_user_service_url_no_cac + '/get-datasets-with-permissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then(data => {
        if(data != null && data.status == '200') {            
          var res = data.response;

          var names = res.map(item => item.dataset);

          names.sort((a, b) => {
            const nameA = a.toUpperCase();
            const nameB = b.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setDatasets(names);
        }
      })
      .catch(error => {
        console.log(error);
      });
  } 

  function loadPersonas() {
    var token = getToken()
    if(token === false) {
        logout()        
        return;
    }
  
    fetch(default_data.default_chat_service_url + '/get-personas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then(data => {
        var res = data.response;
        if(data != null && data.status == '200') {            
          res.sort((a, b) => {
            const nameA = a['name'].toUpperCase();
            const nameB = b['name'].toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setPersonas(res)
        }
        else if(data != null && data.status == '400') {
          if(data.response.indexOf('Token is invalid') > -1) {
            logout()
          }
        }            
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Stack direction={'column'} spacing={0} sx={{ padding: 1, width: 350, height: '100%' }}>
        <Stack direction={'row'} spacing={1} sx={{ paddingBottom: 0, alignItems: 'center', position: 'sticky', top: 0, zIndex: 1 }}>
          <IconButton onClick={onClose}>
            <IoClose />
          </IconButton>
          <Typography variant='h6'>
            Settings
          </Typography>
        </Stack>
        <Tabs value={tab} onChange={(e, v) => setTab(v)} sx={{ position: 'sticky', top: 48, zIndex: 1 }}>
          <Tab label="Customization" value={"customization"} />
          <Tab label="Tokens" value={"tokens"} />
          <Tab label="Account" value={"account"} />
        </Tabs>
        <Stack direction={'column'} spacing={0} sx={{ p: 0, pt:1, overflowY: 'auto', flexGrow: 1 }}>
          <Stack direction={'column'} spacing={1}>
            {tab === 'customization' && (
              <CustomizationTab 
                onClose={onClose}
                personas={personas}
                datasets={datasets}
                setShowAPIKeysList={setShowAPIKeysList}
                user={user}
                loading={loading}
                refetch={updateInfo}
              />
            )}
            {tab === 'tokens' && (
              <TokensTab 
                inferenceTokens={inferenceTokens}
                teachTokens={teachTokens}
                maxInferenceTokens={maxInferenceTokens}
                maxTeachTokens={maxTeachTokens}
                loadingInferenceTokens={loadingInferenceTokens}
                loadingTeachTokens={loadingTeachTokens}
                loadingMaxTokens={loadingMaxTokens}
                refetchInferenceTokens={refetchInferenceTokens}
                refetchTeachTokens={refetchTeachTokens}
                refetchMaxTokens={refetchMaxTokens}
                errorInferenceTokens={errorInferenceTokens}
                errorTeachTokens={errorTeachTokens}
                errorMaxTokens={errorMaxTokens}
              />
            )}
            {tab === 'account' && (
              <AccountTab
                loading={loading}
                onClose={onClose}
                setShowAPIKeysList={setShowAPIKeysList}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};