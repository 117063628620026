import { Divider, Drawer, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { SelectLive } from '../../Chat/Components/SelectLive';

export const ChatInputDrawer = ({ 
  open, 
  onClose, 
  live,
  handleLive,
  paid,
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Stack direction={'column'} spacing={0} sx={{ maxWidth:500 }}>
        <Stack direction={'row'} spacing={1} sx={{ p: 2, alignItems: 'center' }}>
          <IconButton onClick={onClose}>
            <IoClose />
          </IconButton>
          <Typography variant='h6'>
            Data Settings
          </Typography>
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{paddingX:2}}>
          <Typography variant='body2'>
            Ask Sage has two ways to customize the interaction with the AI Model
          </Typography>
          <ul style={{ fontSize: '0.875rem'}}>
            <li>The live feature is a powerful feature that allows users to gather a real-time web results for up-to-date information.</li>
            <li style={{marginLeft: 16}}>Live: Will pull 10 results from Bing</li>
            <li style={{marginLeft: 16}}>Live+: Will pull the 10 results from Bing and also summarize the top 2 web pages using our Web crawler.</li>
            <li>Temperature determines how creative the AI will be when responding. 0 means the AI will not be creative and 1 will allow the AI to be very creative though this can lead to hallucinations.</li>
          </ul>
          <Stack direction={'column'} sx={{width: isMobile ? '100%' : '50%'}} spacing={2}>
            <Stack direction={'column'} spacing={0}>
              <Typography variant='body2'>
                Live
              </Typography>
              <SelectLive 
                onChange={handleLive}
                paid={paid}
                value={live}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}
