const scrollToBottom = async () => {
  try {
    const chatLog = document.getElementsByClassName("chat-log")[0];
    if (chatLog && chatLog.lastElementChild) {
      const lastChild = chatLog.lastElementChild;
      const offsetTop = lastChild.offsetTop;

      chatLog.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }
  catch (error) {
    console.error(error);
  }
};

function checkMode(currentChats, key, mode) {
  try {
    var newChats = currentChats.filter(a => a.id === key)
    if (newChats.length === 0) {
      return true;
    }
    var chat = newChats[0];
    // check items in content
    let found_code = false;
    if (chat.content && chat.content.length > 0) {
      // loop through each item and if they have a .code property, check if it is null
      for (var i = 0; i < chat.content.length; i++) {
        if (chat.content[i].code !== null && chat.content[i].code !== undefined) {  
          found_code = true;
          break;
        }
      }
    }
    if (chat.content && chat.content.length === 1) {
      return true;
    }
    if (mode === 'code' && found_code) {
      return true;
    }
    if (mode === 'chat' && !found_code) {
      return true;
    }
    return false;
  }
  catch (e) {
    console.log(e)
    return true;
  }
}

export { scrollToBottom, checkMode };