import { useEffect, useState } from 'react';
import { Box, Button, Typography, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SVGLogo } from './Components/SVGLogo';
import { ChatActionButtons } from './Components/ChatActionButtons';
import { FollowUp } from './Components/FollowUp';
import { ChatMessageAlerts } from './Components/ChatMessageAlerts';
import { MarkdownRenderer } from './MarkdownRenderer';
import { useUserSettings } from '../Contexts/UserPreferenceContext';
import { ImageComponent } from '../Components/ImageComponent';
import { AccordionFileComponent } from '../Components/AccordionFileComponent';

export const ChatMessage = ({ message, clickHandler, index, clickHandlerFollowUp, clickHandlerDelete, clickHandlerCopy, clickHandlerDown, isLastMeMessage, isLastSageMessage, clickHandlerEdit, setChatInput }) => {
  const [explainability, setExplainability] = useState(false);
  const isMobile = useMediaQuery('(max-width:750px)');
  const theme = useTheme();
  const {state, dispatch} = useUserSettings();

  function changeExplainability() {
    setExplainability(!explainability)
  }

  const showMessageFollowUp = () => {
    if(state.showAllRecommendations) {
      return true;
    } else {
      if(isLastSageMessage) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <Box padding={1} flexGrow={1} width={'100%'}>
      <Box sx={{ display: 'flex', alignItems: 'start' }}>
        <Box sx={{ mr: isMobile ? 'auto' : 2, height: '100%', alignItems:'start', justifyContent:'start', display: 'flex'}}>
          {message.user === 'gpt' ? 
            <SVGLogo /> : 
            <Box sx={{
              backgroundColor: 'primary.main',
              color: 'white',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Typography fontSize={14}>You</Typography>
            </Box>}
        </Box>
        {!isMobile && <Stack direction={'column'} spacing={1} flexGrow={1} width={'calc(100% - 56px - 84px)'}>
          <Box
            sx={{ 
              fontSize: 16,
              'code': {
                wordBreak: 'break-word',
              },
              'a': {
                color: 'text.primary',
                wordBreak: 'break-word',
              },
              'table': {
                overflowX: 'auto',
                maxWidth: '100%',
                display: 'block',
                whiteSpace: 'nowrap',
                backgroundColor: 'transparent !important',
                '&::-webkit-scrollbar': {
                  height: '8px'
                },
              },
              'thead': {
                backgroundColor: `${theme.palette.table.header.backgroundColor} !important`,
                color: `${theme.palette.table.header.color} !important`,
              },
              'tbody': {
                backgroundColor: `${theme.palette.table.body.backgroundColor} !important`,
                color: `${theme.palette.text.primary} !important`,
                'tr:nth-of-type(odd)': {
                  backgroundColor: `${theme.palette.table.body.backgroundColorLight} !important`,
                },
                'tr:nth-of-type(even)': {
                  backgroundColor: `${theme.palette.table.body.backgroundColor} !important`,
                },
              },
            }}
            flexGrow={1}
            >
            <AccordionFileComponent content={message} />             
            <ImageComponent content={message} />             
            <MarkdownRenderer content={message.message} user={message.user} />
          </Box>
          <Box display={{ xs: 'none', sm: 'block' }}>
            <ChatMessageAlerts message={message} explainability={explainability} changeExplainability={changeExplainability} />
          </Box>
        </Stack>}
        <ChatActionButtons
          message={message}
          id={message.id || ''}
          isLastMeMessage={isLastMeMessage}
          clickHandlerCopy={clickHandlerCopy}
          clickHandlerDelete={clickHandlerDelete}
          clickHandlerDown={clickHandlerDown}
          clickHandlerEdit={clickHandlerEdit}
          setChatInput={setChatInput}
        />
      </Box>
      {isMobile && (
        <Stack direction={'column'} spacing={1} flexGrow={1} mt={1} width={'100%'}>
          <Box
            sx={{ 
              fontSize: 16,
              'code': {
                wordBreak: 'break-word',
              },
              'a': {
                color: 'text.primary',
                wordBreak: 'break-word',
              },
              'table': {
                overflowX: 'scroll',
                maxWidth: '100%',
                display: 'block',
                whiteSpace: 'nowrap',
              },
            }}
            flexGrow={1}
          >
            <AccordionFileComponent content={message} />
            <ImageComponent content={message} />
            <MarkdownRenderer content={message.message} user={message.user} />
          </Box>
          <Box display={{ xs: 'none', sm: 'block' }}>
            <ChatMessageAlerts message={message} explainability={explainability} changeExplainability={changeExplainability} />
          </Box>
        </Stack>
      )}
      <Box display={{ xs: 'block', sm: 'none' }} mt={2}>
        <ChatMessageAlerts message={message} explainability={explainability} changeExplainability={changeExplainability} />
      </Box>
      <Box sx={{mt:2}}>
        {showMessageFollowUp() && message.follows && message.follows.map((followUpMessage, index) => (
          <FollowUp key={index} message={followUpMessage} clickHandler={clickHandlerFollowUp} setChatInput={setChatInput} />
        ))}
      </Box>
    </Box>
  )
}