import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Box, IconButton, DialogActions, Button, useTheme, useMediaQuery } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { ScrollableDialogContent } from '../../Components/ScrollableDialogContent';
import { FileManagementFilters } from './FileManagementFilters';
import { FileManagementTable } from './FileManagementTable';
import { useFetchFiles } from './queries';
import { GroupedFiles } from './GroupedFiles';
import { CopyFilesDialog } from './CopyFilesDialog';

export const FileManagementDialog = ({
  triggerFilesPopup,
  setTriggerFilesPopup,
  datasetNames,
  datasetPermissions,
  setDatasets,
  loadDatasets,
  triggerIngestPopup,
}) => {
  const theme = useTheme();
  const [groupDatasets, setGroupDatasets] = useState(true);
  const [filterFilename, setFilterFilename] = useState('');
  const [filterDataset, setFilterDataset] = useState('');
  const [openFiles, setOpenFiles] = useState([]);
  const [openDatasets, setOpenDatasets] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [copyDialog, setCopyDialog] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, fetching, error, refetch } = useFetchFiles();

  const openAll = () => {
    setOpenFiles(data.map((file) => `${file.filename}-(${file.source_string})`));
    //only get the datasets that have files
    const datasets = data.map((file) => file.source_string);
    const uniqueDatasets = [...new Set(datasets)];
    setOpenDatasets(uniqueDatasets);
  }

  const closeAll = () => {
    setOpenFiles([]);
    setOpenDatasets([]);
  }

  useEffect(() => {
    if (triggerFilesPopup) {
      setSelectedFiles([]);
      setOpenFiles([]);
      setOpenDatasets([]);
    }
  }, [triggerFilesPopup]);

  useEffect(() => {
    if (data) {
      const datasetsFiltered = datasetNames.filter((dataset) => {
        const pattern = /user_custom_\d+_[^_]+_content/;
        return pattern.test(dataset)
      });

      const uniqueDatasets = [
        ...new Set(
          datasetsFiltered.filter((dataset) => 
            dataset?.toLowerCase().includes(filterDataset?.toLowerCase())
        )
      )];
      const output = uniqueDatasets.map((dataset) => {
        const files = data.filter((file) => 
          file.source_string === dataset && file.filename?.toLowerCase().includes(filterFilename?.toLowerCase())
        );
        return {
          source: dataset,
          files: files,
        }
      })
      setSortedData(output);
    }
  }, [data, filterFilename, filterDataset, datasetNames]);

  return (
    <>
      <Dialog
        open={triggerFilesPopup}
        onClose={() => setTriggerFilesPopup(false)}
        aria-labelledby="file-management-dialog"
        fullWidth={true}
        maxWidth="xl"
        fullScreen={fullScreen}
      >
        <DialogTitle id="file-management-dialog-title" padding={{xs: 1, sm: 2}}> 
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {groupDatasets ? 'Manage Datasets': 'Manage Files'}
            <IconButton onClick={() => { setTriggerFilesPopup(false) }}>
              <IoClose />
            </IconButton>
          </Box>
        </DialogTitle>
        <ScrollableDialogContent sx={{ height: '80vh', padding:{ xs: 0, sm: 2}}} modalIsOpen={triggerFilesPopup}>
          <FileManagementFilters 
            groupDatasets={groupDatasets}
            setGroupDatasets={setGroupDatasets}
            filterFilename={filterFilename}
            setFilterFilename={setFilterFilename}
            filterDataset={filterDataset}
            setFilterDataset={setFilterDataset}
            openAll={openAll}
            closeAll={closeAll}
            openFiles={openFiles}
            openDatasets={openDatasets}
            loadDatasets={loadDatasets}
            triggerIngestPopup={triggerIngestPopup}
          />
          {groupDatasets && (
            <GroupedFiles 
              datasetPermissions={datasetPermissions}
              data={sortedData}
              openDatasets={openDatasets}
              setOpenDatasets={setOpenDatasets}
              openFiles={openFiles}
              setOpenFiles={setOpenFiles}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setDatasets={setDatasets}
              isFiltering={filterFilename !== ''}
              refetch={refetch}
            />
          )}
          {!groupDatasets && (
            <FileManagementTable 
              datasetPermissions={datasetPermissions}
              data={sortedData.map((dataset) => dataset.files).flat()}
              fetching={fetching}
              groupDatasets={false}
              openFiles={openFiles}
              setOpenFiles={setOpenFiles}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              refetch={refetch}
            />
          )}

        </ScrollableDialogContent>
        {selectedFiles.length > 0 && (
          <DialogActions sx={{ justifyContent: 'flex-start', paddingX: 3, paddingBottom: 2}} >
            <Button onClick={()=>{ setCopyDialog(true)}} color="primary" variant='contained'>
              Copy {selectedFiles.length} files
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {copyDialog && (
        <CopyFilesDialog
          open={copyDialog}
          setOpen={setCopyDialog}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          refetch={refetch}
          datasets={datasetNames}
        />
      )}
    </>
  )
}