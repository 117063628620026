import React, { useState, useCallback, useEffect, useRef } from 'react';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, IconButton } from '@mui/material';
import { FiCopy } from "react-icons/fi";
import { FaUndo, FaRedo, FaEye, FaEyeSlash } from "react-icons/fa";
import { diffLines } from 'diff';
import { Decoration } from '@codemirror/view';

export default function CodeRenderer({
  markdown,
  language = "javascript",
  onMarkdownChange,
  history,
  currentHistoryIndex,
  setHistory,
  setCurrentHistoryIndex,
}) {
  const [copied, setCopied] = useState(false);
  const [highlightChanges, setHighlightChanges] = useState(false);

  // Ref to store the latest value of markdown
  const latestMarkdown = useRef(markdown);
  // Timer ID for debouncing
  const debounceTimer = useRef(null);

  const handleCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, []);

  function getCodeMirrorLanguageExtension(language) {
    const synonyms = {
      js: 'javascript',
      py: 'python',
      golang: 'go',
      ts: 'typescript',
      md: 'markdown',
      apl: 'apl',
      asciiArmor: 'asciiArmor',
      asterisk: 'asterisk',
      c: 'c',
      csharp: 'csharp',
      scala: 'scala',
      kotlin: 'kotlin',
      shader: 'shader',
      nesC: 'nesC',
      objectiveC: 'objectiveC',
      objectiveCpp: 'objectiveCpp',
      squirrel: 'squirrel',
      ceylon: 'ceylon',
      dart: 'dart',
      cmake: 'cmake',
      cobol: 'cobol',
      commonLisp: 'commonLisp',
      crystal: 'crystal',
      cypher: 'cypher',
      d: 'd',
      diff: 'diff',
      dtd: 'dtd',
      dylan: 'dylan',
      ebnf: 'ebnf',
      ecl: 'ecl',
      eiffel: 'eiffel',
      elm: 'elm',
      factor: 'factor',
      fcl: 'fcl',
      forth: 'forth',
      fortran: 'fortran',
      gas: 'gas',
      gherkin: 'gherkin',
      groovy: 'groovy',
      haskell: 'haskell',
      haxe: 'haxe',
      http: 'http',
      idl: 'idl',
      jinja2: 'jinja2',
      mathematica: 'mathematica',
      mbox: 'mbox',
      mirc: 'mirc',
      modelica: 'modelica',
      mscgen: 'mscgen',
      mumps: 'mumps',
      nsis: 'nsis',
      ntriples: 'ntriples',
      octave: 'octave',
      oz: 'oz',
      pig: 'pig',
      properties: 'properties',
      protobuf: 'protobuf',
      puppet: 'puppet',
      q: 'q',
      sas: 'sas',
      sass: 'sass',
      sieve: 'sieve',
      smalltalk: 'smalltalk',
      solr: 'solr',
      sparql: 'sparql',
      spreadsheet: 'spreadsheet',
      stex: 'stex',
      textile: 'textile',
      tiddlyWiki: 'tiddlyWiki',
      tiki: 'tiki',
      troff: 'troff',
      ttcn: 'ttcn',
      turtle: 'turtle',
      velocity: 'velocity',
      verilog: 'verilog',
      vhdl: 'vhdl',
      webIDL: 'webIDL',
      xQuery: 'xQuery',
      yacas: 'yacas',
      z80: 'z80',
      wast: 'wast',
      javascript: 'javascript',
      jsx: 'jsx',
      typescript: 'typescript',
      tsx: 'tsx',
      json: 'json',
      html: 'html',
      css: 'css',
      python: 'python',
      markdown: 'markdown',
      xml: 'xml',
      sql: 'sql',
      mysql: 'mysql',
      pgsql: 'pgsql',
      java: 'java',
      rust: 'rust',
      cpp: 'cpp',
      lezer: 'lezer',
      php: 'php',
      go: 'go',
      shell: 'shell',
      lua: 'lua',
      swift: 'swift',
      tcl: 'tcl',
      yaml: 'yaml',
      vb: 'vb',
      powershell: 'powershell',
      brainfuck: 'brainfuck',
      stylus: 'stylus',
      erlang: 'erlang',
      nginx: 'nginx',
      perl: 'perl',
      ruby: 'ruby',
      pascal: 'pascal',
      livescript: 'livescript',
      less: 'less',
      scheme: 'scheme',
      toml: 'toml',
      vbscript: 'vbscript',
      clojure: 'clojure',
      coffeescript: 'coffeescript',
      julia: 'julia',
      dockerfile: 'dockerfile',
      r: 'r'
    };

    const lower = language.toLowerCase();
    const officialLang = synonyms[lower] || lower;

    if (langs[officialLang]) {
      return langs[officialLang]();
    }

    return [];
  }

  const extension = getCodeMirrorLanguageExtension(language);

  const onChange = useCallback(
    (value) => {
      // Update the latest markdown value
      latestMarkdown.current = value;

      // Clear the existing timer if the user is still typing
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      // Set a new timer to call onMarkdownChange after 2 seconds of inactivity
      debounceTimer.current = setTimeout(() => {
        onMarkdownChange(latestMarkdown.current);
        debounceTimer.current = null; // Reset the timer ID

        // Update history and current index
        setHistory((prevHistory) => {
          const newHistory = [
            ...prevHistory.slice(0, currentHistoryIndex + 1),
            latestMarkdown.current,
          ];
          if (newHistory.length > 20) {
            newHistory.shift(); // Remove the oldest entry if history exceeds 20 entries
          }
          setCurrentHistoryIndex(newHistory.length - 1);
          return newHistory;
        });
      }, 2000);
    },
    [onMarkdownChange, currentHistoryIndex, setHistory, setCurrentHistoryIndex]
  );

  useEffect(() => {
    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    // Update history when markdown prop changes from outside
    if (markdown !== latestMarkdown.current) {
      latestMarkdown.current = markdown;
      setHistory((prevHistory) => {
        // Check if the new markdown is already the last item in the history
        if (prevHistory[currentHistoryIndex] !== markdown) {
          const newHistory = [
            ...prevHistory.slice(0, currentHistoryIndex + 1),
            markdown,
          ];
          if (newHistory.length > 20) {
            newHistory.shift(); // Remove the oldest entry if history exceeds 20 entries
          }
          setCurrentHistoryIndex(newHistory.length - 1);
          return newHistory;
        }
        return prevHistory;
      });
    }
  }, [markdown, currentHistoryIndex, setHistory, setCurrentHistoryIndex]);

  const handleUndo = () => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex(currentHistoryIndex - 1);
      onMarkdownChange(history[currentHistoryIndex - 1]);
      setHighlightChanges(false);
    }
  };

  const handleRedo = () => {
    if (currentHistoryIndex < history.length - 1) {
      setCurrentHistoryIndex(currentHistoryIndex + 1);
      onMarkdownChange(history[currentHistoryIndex + 1]);
      setHighlightChanges(false);
    }
  };

  const handleToggleHighlight = () => {
    setHighlightChanges(!highlightChanges);
  };

  const getChangedLines = (oldText, newText) => {
    // Generate an array of diff "parts" between oldText and newText
    const diff = diffLines(oldText, newText, { newlineIsToken: true });
    const changedLines = new Set();
  
    let newLineIndex = 0;
  
    diff.forEach((part) => {
      // Split on newlines
      let lines = part.value.split(/\r?\n/);
  
      // If the chunk ends in a newline, the last split is an empty string.
      // Pop it off to avoid counting an extra line.
      if (!lines[lines.length - 1]) {
        lines.pop();
      }
  
      if (part.added) {
        // For each added line, mark its index in the new text
        for (let i = 0; i < lines.length; i++) {
          changedLines.add(newLineIndex + 1); // +1 because lines are 1-indexed
          newLineIndex++;
        }
      } else if (part.removed) {
        // These lines do not exist in the *new* text,
        // so do NOT advance newLineIndex
      } else {
        // Unchanged lines: just advance the new line index
        newLineIndex += lines.length;
      }
    });
  
    return changedLines;
  };
  
  const changedLines =
    highlightChanges && currentHistoryIndex > 0
      ? getChangedLines(history[currentHistoryIndex - 1], markdown)
      : new Set();

  const highlightExtension = EditorView.decorations.compute(
    [markdown, highlightChanges],
    (state) => {
      if (!highlightChanges) {
        return Decoration.set([]);
      }

      const decorations = [];
      for (let i = 1; i <= state.doc.lines; i++) {
        if (changedLines.has(i)) {
          const line = state.doc.line(i);
          decorations.push(
            Decoration.line({
              attributes: { class: "highlight-line" },
            }).range(line.from)
          );
        }
      }
      return Decoration.set(decorations);
    }
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start"
        }}
      >
        <CopyToClipboard text={markdown} onCopy={handleCopy}>
          <Tooltip title="Copy" placement='right'>
            <IconButton
            color="primary"
            size="large"
            aria-label="Copy code"
          >
            {copied ? (
              <span style={{ fontSize: '1rem' }}>Copied!</span>
            ) : (
              <FiCopy alt="Copy" size={22} />
            )}
          </IconButton>
          </Tooltip>
        </CopyToClipboard>
        <Tooltip title="Undo" placement='right'>
          <IconButton
            color="secondary"
            size="large"
            onClick={handleUndo}
            disabled={currentHistoryIndex === 0}
          >
            <FaUndo size={22} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Redo" placement='right'>
          <IconButton
            color="secondary"
            size="large"
            onClick={handleRedo}
            disabled={currentHistoryIndex === history.length - 1}
          >
            <FaRedo size={22} />
          </IconButton>
        </Tooltip>
        {history.length > 1 && currentHistoryIndex > 0 && (
          <Tooltip title="Changes" placement='right'>
            <IconButton
            color="default"
            size="large"
            onClick={handleToggleHighlight}
          >
              {highlightChanges ? <FaEyeSlash size={22} /> : <FaEye size={22} />}
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div
        style={{
          flex: 1,
          width: "100%",
          overflow: "auto",
        }}
      >
        <CodeMirror
          value={markdown}
          height="auto"
          style={{ width: "100%" }}
          theme={"dark"}
          basicSetup={{
            lineNumbers: true,
            history: true,
            matchBrackets: true,
            autoCloseBrackets: true,
            styleActiveLine: true,
            matchTags: true,
            highlightActiveLineGutter: true,
            autocompletion: true,
            autoCloseTags: true,
          }}
          extensions={[extension, EditorView.lineWrapping, highlightExtension]}
          onChange={onChange}
        />
      </div>
    </div>
  );
}