import { Box, useTheme } from "@mui/material"
import { FiGlobe } from "react-icons/fi" 
import * as default_data from '../../config.js'

export const LiveSearchToggle = ({ liveSearch, setLiveSearch, paid }) => {
  const theme = useTheme()

  const toggleLiveSearch = () => {
    let newValue = 0
    if (liveSearch === 0) {
      newValue = paid ? 2 : 1
    }
    setLiveSearch({ target: { value: newValue } })
  }

  if (default_data.default_block_live === true) {
    return null
  }

  return (
    <Box
      onClick={toggleLiveSearch}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 4,
        p: 0.5,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <FiGlobe
        size={22}
        color={
          liveSearch > 0
            ? theme.palette.primary.dark
            : theme.palette.text.secondary
        }
      />
    </Box>
  )
}
