import { Card, Skeleton, Stack, Typography } from "@mui/material";

export const SettingsCard = ({ title, cardColor, children, loading, loadingCardHeight }) => {
  if (loading) {
    return (
      <Card sx={{ p: 2, borderRadius: 2, backgroundColor: cardColor, height: loadingCardHeight }}>
        <Stack direction={'column'} spacing={1}>
          <Skeleton variant="text" width={'100%'} height={40} />
          <Skeleton variant="text" width={'100%'} height={40} />
          <Skeleton variant="text" width={'100%'} height={40} />
        </Stack>
      </Card>
    );
  }

  return (
    <Card sx={{ p: 2, borderRadius: 2, backgroundColor: cardColor }}>
      {title && <Typography variant='h6' fontSize={'16px'} mb={1} fontWeight={600}>
        {title}
      </Typography>}
      {children}
    </Card>
  );
};