import React, { useEffect, useState } from 'react';
import { unEscapeHTML } from '../Utils/text_functions.js';
import { Alert } from '@mui/material';

export const ImageComponent = ({ content }) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    if (content && content.images) {
      try {
        const parsedImages = JSON.parse(content.images);
        setImages(parsedImages);
      } catch (error) {
        console.error('Failed to parse images:', error);
      }
    }
  }, [content]);

  const createObjectURL = (base64) => {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return URL.createObjectURL(blob);
  };

  return (
    images && images.images && images.images.urls ? (
      <>
        {images.images.urls.map((imageSrc, index) => {
          const src = images.images.model === 'google-imagen-3' ? createObjectURL(`data:image/jpeg;base64,${imageSrc}`) : unEscapeHTML(imageSrc);
          return (
            <a key={index} href={src} target="_blank" rel="noopener noreferrer">
              <img className="dall-e-image" src={src} alt={`Generated ${index}`} />
            </a>
          );
        })}
        <Alert severity="warning" sx={{ marginTop: 3 }}>
            Please make sure to download those images as they are ephemeral and will not persist here due to security reasons.
        </Alert>
      </>
    ) : null
  );
};