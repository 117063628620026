import React, { useState, useEffect } from 'react';
import * as default_data from '../../config.js';
import { getUser, getToken } from '../../AuthService.js';
import AddWorkbook from './AddWorkbook.js';
import { unEscapeHTML } from '../../Utils/text_functions.js';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { Typography, Box, Dialog, Stack, DialogTitle, Button, TextField, Card, CardContent, useTheme, DialogContent, DialogActions } from '@mui/material';
import { NoResults } from '../../Components/NoResults.js';

const WorkbookList = ({ handleWorkbookClick, triggerWorkbookList, setTriggerWorkbookList }) => {
  const [workbooks, setWorkbooks] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [triggerAddWorkbook, setTriggerAddWorkbook] = useState(0);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [workbookToDelete, setWorkbookToDelete] = useState(null);
  var loaded_static = false;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const theme = useTheme();

  function addWorkbook() {
    setTriggerAddWorkbook(1);
  }

  function closeModal() { }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function confirmDelete() {
    if (workbookToDelete == null) {
      return;
    }

    var token = getToken();
    if (token === false) {
      return;
    }

    var user = getUser();

    if (user === false || user == null) {
      window.location = '/login';
    }
    setUserId(user.id);

    fetch(default_data.default_user_service_url_no_cac + '/workbook', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`,
      },
      body: JSON.stringify({
        workbook_id: workbookToDelete,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then((data) => {
        if (data.status != 200) {
          return;
        }
        loadWorkbooks();
        setDeleteModalIsOpen(false);
        setWorkbookToDelete(null);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function loadWorkbooks() {
    var token = getToken();
    if (token === false) {
      return;
    }

    var user = getUser();

    if (user === false || user == null) {
      window.location = '/login';
    }
    setUserId(user.id);

    fetch(default_data.default_user_service_url_no_cac + '/get-workbooks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then((data) => {
        if (data.status != 200) {
          return;
        }
        data = data.response;
        setWorkbooks(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (triggerWorkbookList) {
      if (triggerWorkbookList === 1) {
        setIsOpen(true);
      }
    }
  }, [triggerWorkbookList]);

  useEffect(() => {
    if (loaded || loaded_static) {
      return;
    }

    setLoaded(true);
    loaded_static = true;

    loadWorkbooks();
  }, []);

  const filterWorkbooks = () => {
    if (!workbooks) return [];
    return workbooks.filter((workbook) =>
      workbook.title.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const DisplayData = () => {
    const filteredWorkbooks = filterWorkbooks();
    if (filteredWorkbooks.length === 0) {
      return <NoResults message={'No Workbooks Found'} />;
    }
    return filteredWorkbooks.map((info) => {
      return <WorkbookVignette key={info.id} info={info} workbookClick={workbookClick} />;
    });
  };

  const WorkbookVignette = ({ info, workbookClick }) => {
    return (
      <Card
        key={info.id}
        tag={info.id}
        onClick={workbookClick}
        variant="outlined"
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '1rem',
          border: `1px solid ${theme.palette.divider}`,
          cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        }
        }}
      >
        <CardContent tag={info.id} sx={{ padding: '0.5rem' }}>
          <Stack direction={'row'} spacing={2}>
            <div tag={info.id} className="workbook-item-mainblock">
              <Typography variant='subtitle1' tag={info.id}>
                {unEscapeHTML(info.title)}
              </Typography>
              <Typography color={theme.palette.text.secondary} tag={info.id} className="workbook-item-description" variant='body2'>
                {info.overall_summary !== null && info.overall_summary !== '' ? unEscapeHTML(info.overall_summary.substring(0, 400)) + '...' : ''}
              </Typography>
            </div>
            <div tag={info.id} className="workbook-item-deleteblock">
              {info.user_id == user_id ? (
                <span
                  className="workbook-list-button-nobg"
                  data-key={info.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setWorkbookToDelete(info.id);
                    setDeleteModalIsOpen(true);
                  }}
                >
                  <FiTrash2 style={{ color: theme.palette.text.primary }} data-key={info.id} size={20} />
                </span>
              ) : (
                ''
              )}
            </div>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  function workbookClick(e) {
    var tag = e.target.getAttribute('tag');
    if (tag == null) {
      return;
    }
    tag = tag.toString();
    var workbook = null;
    workbooks.forEach((element) => {
      if (element['id'] == tag) {
        workbook = element;
      }
    });

    if (workbook != null && workbook != '') {
      handleWorkbookClick(workbook);
      closeModal();
    }
  }

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{        
        padding: 2,
        backgroundColor: theme.palette.background.default,
        width: '100%',
        height: '100%',
      }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          Workbooks (Beta)
        </Box>
      </Stack>
      <Stack 
        direction={{
          xs: 'column',
          sm: 'row',
        }} 
        spacing={1}
        marginBottom={{ xs: 2, sm: 0 }}
      >
        <Button sx={{ whiteSpace: 'nowrap', minWidth: 'max-content'}} onClick={addWorkbook} variant="contained" startIcon={<FiPlus/>}>
          New Workbook
        </Button>
        <TextField
          type="text"
          placeholder="Search workbooks"
          value={searchText}
          onChange={handleSearchChange}
          size="small"
          fullWidth
        />
      </Stack>

      <div className="workbook-container" style={{ overflowY: 'auto', paddingRight: '20px' }}>
        {workbooks === null ? (
          'Loading...'
        ) : (
          <DisplayData />
        )}
      </div>

      <AddWorkbook
        loadWorkbooks={loadWorkbooks}
        setTriggerAddWorkbook={setTriggerAddWorkbook}
        triggerAddWorkbook={triggerAddWorkbook}
      />
      <Dialog
        open={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Workbook</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this workbook?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color={'error'} variant='contained' onClick={confirmDelete}>Delete</Button>
          <Button onClick={() => setDeleteModalIsOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
export default WorkbookList;