import { Alert, Box, IconButton, Menu, MenuItem, useTheme, Snackbar, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import { FiEdit, FiSave, FiThumbsDown, FiTrash2} from 'react-icons/fi';
import { LuCopy, LuCopyMinus } from "react-icons/lu";
import { isBotLoggedIn } from '../../AuthService.js'
import { HiDotsVertical } from "react-icons/hi";
import { useState } from 'react';
import { unEscapeHTML, stripMarkdown } from '../../Utils/text_functions.js'


export const ChatActionButtons = ({ 
  message, 
  isLastMeMessage,
  clickHandlerDelete, 
  clickHandlerDown, 
  clickHandlerEdit,
  setChatInput,
  saveMemo
}) => {
  const theme = useTheme()
  const themeColor = theme.palette.text.primary
  const [downVote, setDownVote] = useState(message.embedding_down === 'true' ? true : false)
  const [copyState, setCopyState] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openSnackbar, setOpenSnackbar] = useState(undefined);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(undefined);
  };

  const saveAsMemo = () => {
    setAnchorEl(null)
    saveMemo();
    setOpenSnackbar('Saved as Memo');
  }

  const copyToClipboard = () => {
    setAnchorEl(null)
    navigator.clipboard.writeText(unEscapeHTML(message.message_orig));
    setOpenSnackbar('Copied to clipboard');
  }
  const copyToClipboardNoMarkdown = () => {
    setAnchorEl(null)
    const messageParsed = stripMarkdown(unEscapeHTML(message.message_orig));
    navigator.clipboard.writeText(messageParsed);
    setOpenSnackbar('Copied to clipboard');
  }

  const handleDeleteEvent = (event) => {
    setAnchorEl(null)
    if(message.id) {
      clickHandlerDelete(message.id)
    }
    else {
      clickHandlerDelete(message)
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton
        id="action-menu-button"
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size='small'
      >
        <HiDotsVertical color={themeColor} />
      </IconButton>
      {open && (
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'action-menu-button',
          }}
        >
          <MenuList style={{paddingTop: 1, paddingBottom: 1}} dense sx={{fontSize: 10}}>
            {message.user === 'gpt' && message.id != null && !isBotLoggedIn() && !message.teach && (
              <MenuItem
                onClick={
                  () => {
                    setDownVote(true)
                    setAnchorEl(null)
                    setOpenSnackbar('Thank you for your feedback')
                    clickHandlerDown(message.id)
                  }
                } 
                aria-label="thumbs down"
              >
                <ListItemIcon>
                  <FiThumbsDown color={themeColor} size={16}/>
                </ListItemIcon>
                <ListItemText primary="Downvote" />
              </MenuItem>
            )}
            {message.user === 'gpt' && message.id != null && (
              <MenuItem onClick={copyToClipboard} aria-label="copy">
                <ListItemIcon>
                  <LuCopy color={themeColor} style={{backgroundColor: copyState ? 'burlywood' : 'transparent'}} size={16}/>
                </ListItemIcon>
                <ListItemText primary="Copy Raw" />
              </MenuItem>
            )}
            {message.user === 'gpt' && message.id != null && (
              <MenuItem onClick={copyToClipboardNoMarkdown} aria-label="copy">
                <ListItemIcon>
                  <LuCopyMinus color={themeColor} style={{backgroundColor: copyState ? 'burlywood' : 'transparent'}} size={16}/>
                </ListItemIcon>
                <ListItemText primary="Copy Cleaned" />
              </MenuItem>
            )}
            {message.user === 'me' && message.id != null && isLastMeMessage && (
              <MenuItem onClick={() => clickHandlerEdit(message.id, message.message, setChatInput)} aria-label="edit">
                <ListItemIcon>
                  <FiEdit color={themeColor} size={16} />
                </ListItemIcon>
                <ListItemText primary="Edit" />
              </MenuItem>
            )}
            <MenuItem onClick={() => handleDeleteEvent()} aria-label="delete">
              <ListItemIcon>
                <FiTrash2 color={themeColor} size={16}/>
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
            {message.user === 'gpt' && message.message.indexOf('Welcome, how can I help you?') === -1 && message.message.indexOf("I'm thinking, please wait...") === -1 ? 
              <MenuItem onClick={saveAsMemo} aria-label="copy">
              <ListItemIcon>
                <FiSave color={themeColor} style={{backgroundColor: copyState ? 'burlywood' : 'transparent'}} size={16}/>
              </ListItemIcon>
              <ListItemText primary="Save as Memo" />
            </MenuItem>
            : ''}            
          </MenuList>
        </Menu>
      )}
      { openSnackbar && (
        <Snackbar open={openSnackbar !== undefined } autoHideDuration={2000} onClose={handleCloseSnackbar}>
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {openSnackbar}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};
