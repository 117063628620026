import { Button, Divider, Drawer, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { IoClose, IoOpenOutline } from 'react-icons/io5';
import { SelectDataset } from '../ChatBox';
import { SelectLive } from '../Components/SelectLive';
import { SelectTemperature } from '../Components/SelectTemperature';
import { MdCloudQueue, MdFileCopy } from 'react-icons/md';
import * as default_data from '../../config.js'

export const ChatInputDrawer = ({ 
  open, 
  onClose, 
  selectedDatasets, 
  datasets, 
  onChange,
  temperature,
  handleSeletTemperature,
  live,
  handleLive,
  paid,
  showSimpleIngest,
  showCopyData,
  currentMode,
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Stack direction={'column'} spacing={0} sx={{ maxWidth:500 }}>
        <Stack direction={'row'} spacing={1} sx={{ p: 2, alignItems: 'center' }}>
          <IconButton onClick={onClose}>
            <IoClose />
          </IconButton>
          <Typography variant='h6'>
            Data Settings
          </Typography>
        </Stack>
        <Stack direction={'column'} spacing={0} sx={{ p: 2 }}>
          <Typography variant='h6' fontWeight={'bold'}>
            Data Collections (Datasets)
          </Typography>
          <Divider orientation="vertical" flexItem />
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{paddingX:2}}>
          <Typography variant='body2'>
            Datasets are a way of storing documents. Select which datasets will be used for your query to the AI models.
          </Typography>
          <SelectDataset 
            value={selectedDatasets}
            datasets={datasets}
            onChange={onChange}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{marginTop: 2, width: isMobile ? '100%' : '50%'}}
            startIcon={<MdCloudQueue/>}
            onClick={showSimpleIngest}
          >
            Upload New Files
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{marginTop: 2, width: isMobile ? '100%' : '50%'}}
            startIcon={<MdFileCopy/>}
            onClick={showCopyData}
          >
            Manage Datasets
          </Button>
        </Stack>
        { currentMode === 'advanced' && (
          <Stack direction={'column'} spacing={0} sx={{ p: 2 }}>
            <Typography variant='h6' fontWeight={'bold'}>
              Model Customization
            </Typography>
            <Divider orientation="vertical" flexItem />
          </Stack>
        )}
        { currentMode === 'advanced' && (
          <Stack direction={'column'} spacing={1} sx={{paddingX:2}}>
            <Typography variant='body2'>
              Ask Sage has two ways to customize the interaction with the AI Model
            </Typography>
            <ul style={{ fontSize: '0.875rem'}}>
              {default_data.default_block_live !== true && <li>The live feature is a powerful feature that allows users to gather a real-time web results for up-to-date information.</li> }
              {default_data.default_block_live !== true && <li style={{marginLeft: 16}}>Live: Will pull 10 results from Bing</li> }
              {default_data.default_block_live !== true && <li style={{marginLeft: 16}}>Live+: Will pull the 10 results from Bing and also summarize the top 2 web pages using our Web crawler.</li> }
              <li>Temperature determines how creative the AI will be when responding. 0 means the AI will not be creative and 1 will allow the AI to be very creative though this can lead to hallucinations.</li>
            </ul>
            <Stack direction={'column'} sx={{width: isMobile ? '100%' : '50%'}} spacing={2}>
              {default_data.default_block_live !== true && (
                <Stack direction={'column'} spacing={0}>
                  <Typography variant='body2'>
                    Live
                  </Typography>
                  <SelectLive 
                    currentMode={currentMode}
                    onChange={handleLive}
                    paid={paid}
                    value={live}
                  />
                </Stack>
              )}
              <Stack direction={'column'} spacing={0}>
                <Typography variant='body2'>
                  Temperature
                </Typography>
                <SelectTemperature 
                  onChange={handleSeletTemperature}
                  value={temperature}
                  fullWidth
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
}
