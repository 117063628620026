import { Paper, useTheme, Link } from '@mui/material'

export const Capabilities = () => {
  const theme = useTheme();
  return (
    <Paper sx={{ padding: 2, mt: 2 }}>
        <h3 className="modal_header">Capabilities</h3>
        <div className="scroller">
          <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src="/schema.png" alt="Ask Sage schema" /><br /><br />
          <ul>
            <li style={{fontWeight: 'bold'}}>NEW! Code Canvas, Model Comparison and Workbook products released!</li>
            <li style={{fontWeight: 'bold'}}>NEW! Multi-modal: Ask Sage supports text, audio and image (generation & analysis). (paid only)</li>
            <li style={{fontWeight: 'bold'}}>NEW! Ask Sage now supports Azure Gov OpenAI GPT 3.5 and GPT 4, 4o, 4o-mini!</li>
            <li style={{fontWeight: 'bold'}}>NEW! Live+ feature: like "Live", it leverages Bing to search real-time result but Live+ loads the first 2 results content for more accurate answers! Warning, this will use more tokens. (paid only)</li>
            <li style={{fontWeight: 'bold'}}>NEW! Speech to text ingestor (mp3, mp4, mpeg, mpga, m4a, wav, webm) (500MB max)<br /></li>
            <li style={{fontWeight: 'bold'}}>New plugin "Data Labeling" allows you to auto label your content. You can customize the prompt and provide a list of labels if your organization has one. Use our API to automate labeling of your data lakes/data warehouse! (paid only)</li>
            <li style={{fontWeight: 'bold'}}>NEW! File plugins and Agents to enable automated tasks (paid only)</li>
            <li>Added Prompt Engineer persona to help you write Prompts to Ask Sage<br />
            To understand how to use it, watch this <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} rel="noreferrer" target="_blank" href="https://www.youtube.com/watch?v=OgYQAS9LY3o&list=PLSIv_F9TtLlyyZM9cxyFvBi3kohnidSOW">video</Link><br /></li>
            <li>Added Prompt Templates now with Public and Private Templates<br />
            Create your own templates and share it (or not) with other Ask Sage users!</li>
            <li>Added /help - get the list of commands</li>
          </ul>
        </div>
      </Paper>
  )
}
